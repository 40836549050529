<template>
  <div class="">
    <div v-b-toggle.back-button-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Field Setting</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="back-button-collapse" class="mt-2">
          <div class="each-custom-address">
            <div v-b-toggle.address1-button-collapse style=" cursor: pointer; border-bottom: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div class="d-flex" style="gap:4px; color: #18191C; font-weight: 500; font-size: 16px" s><b-form-checkbox
                       :id="`Address1-${Date.now()}`"
                v-model="block.showAddressLine1"
                  ></b-form-checkbox>Address 1</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
            <b-collapse accordion="button-address" id="address1-button-collapse" class="mt-2">
              <div :class="!block.showAddressLine1
                  ? 'disabled-address'
                  : ''" class="px-3">
      <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Label text:</span>
        <span class="d-flex align-items-center gap">{{!block.showAddressLine1Label?'Show':'Hide'}}  <b-form-checkbox
                       :id="`Address1-show-title-${Date.now()}`"
                v-model="block.showAddressLine1Label"
                  ></b-form-checkbox></span>
      </label>
      <input type="text"  v-if="block.showAddressLine1Label" placeholder="Address 1" v-model="block.AddressLine1Label" class="w-100 editor-input-control" id="">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Required field:</span>
        <b-form-checkbox
                       :id="`isRequired-Address1-${Date.now()}`"
                v-model="block.isAddressLine1Required"
                  ></b-form-checkbox>
      </label>
      
      <label for="" class="editorStyleTitle mt-2 d-flex align-items-center justify-content-between">
        <span>Placeholder Text:</span>
       
      </label>
      <input type="text"  placeholder="Placeholder" v-model="block.AddressLine1Placeholder" class="w-100 editor-input-control" id="">
    </div>
            </b-collapse>
          </div>
         <!-- Address 2  -->
         <div class="each-custom-address">
            <div v-b-toggle.Address2-button-collapse style=" cursor: pointer; border-bottom: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div class="d-flex" style="gap:4px; color: #18191C; font-weight: 500; font-size: 16px" s><b-form-checkbox
                       :id="`Address2-${Date.now()}`"
                v-model="block.showAddressLine2"
                  ></b-form-checkbox>Address 2</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
            <b-collapse accordion="button-address" id="Address2-button-collapse" class="mt-2">
              <div :class="!block.showAddressLine2
                  ? 'disabled-address'
                  : ''" class="px-3">
      <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Label text:</span>
        <span class="d-flex align-items-center gap">{{!block.showAddressLine2Label?'Show':'Hide'}}  <b-form-checkbox
                       :id="`Address2-show-title-${Date.now()}`"
                v-model="block.showAddressLine2Label"
                  ></b-form-checkbox></span>
      </label>
      <input type="text"  v-if="block.showAddressLine2Label" placeholder="Address 2" v-model="block.AddressLine2Label" class="w-100 editor-input-control" id="">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Required field:</span>
        <b-form-checkbox
                       :id="`isRequired-Address2-${Date.now()}`"
                v-model="block.isAddressLine2Required"
                  ></b-form-checkbox>
      </label>
      
      <label for="" class="editorStyleTitle mt-2 d-flex align-items-center justify-content-between">
        <span>Placeholder Text:</span>
       
      </label>
      <input type="text"  placeholder="Placeholder" v-model="block.AddressLine2Placeholder" class="w-100 editor-input-control" id="">
    </div>
            </b-collapse>
          </div>
          <!-- Address 2 End -->
           <!-- City Start -->
           <div class="each-custom-address">
            <div v-b-toggle.City-button-collapse style=" cursor: pointer; border-bottom: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div class="d-flex" style="gap:4px; color: #18191C; font-weight: 500; font-size: 16px" s><b-form-checkbox
                       :id="`City-${Date.now()}`"
                v-model="block.showCity"
                  ></b-form-checkbox>City/Town</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
            <b-collapse accordion="button-address" id="City-button-collapse" class="mt-2">
              <div :class="!block.showCity
                  ? 'disabled-address'
                  : ''" class="px-3">
      <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Label text:</span>
        <span class="d-flex align-items-center gap">{{!block.showCityLabel?'Show':'Hide'}}  <b-form-checkbox
                       :id="`City-show-title-${Date.now()}`"
                v-model="block.showCityLabel"
                  ></b-form-checkbox></span>
      </label>
      <input type="text"  v-if="block.showCityLabel" placeholder="City" v-model="block.CityLabel" class="w-100 editor-input-control" id="">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Required field:</span>
        <b-form-checkbox
                       :id="`isRequired-City-${Date.now()}`"
                v-model="block.isCityRequired"
                  ></b-form-checkbox>
      </label>
      
      <label for="" class="editorStyleTitle mt-2 d-flex align-items-center justify-content-between">
        <span>Placeholder Text:</span>
       
      </label>
      <input type="text"  placeholder="Sample Text" v-model="block.CityPlaceholder" class="w-100 editor-input-control" id="">
    </div>
            </b-collapse>
          </div>
            <!-- City End -->
             <!-- State Stat -->
             <div class="each-custom-address">
            <div v-b-toggle.State-button-collapse style=" cursor: pointer; border-bottom: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div class="d-flex" style="gap:4px; color: #18191C; font-weight: 500; font-size: 16px" s><b-form-checkbox
                       :id="`State-${Date.now()}`"
                v-model="block.showState"
                  ></b-form-checkbox>State/Region/Province</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
            <b-collapse accordion="button-address" id="State-button-collapse" class="mt-2">
              <div :class="!block.showState
                  ? 'disabled-address'
                  : ''" class="px-3">
      <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Label text:</span>
        <span class="d-flex align-items-center gap">{{!block.showStateLabel?'Show':'Hide'}}  <b-form-checkbox
                       :id="`State-show-title-${Date.now()}`"
                v-model="block.showStateLabel"
                  ></b-form-checkbox></span>
      </label>
      <input type="text"  v-if="block.showStateLabel" placeholder="State" v-model="block.StateLabel" class="w-100 editor-input-control" id="">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Required field:</span>
        <b-form-checkbox
                       :id="`isRequired-State-${Date.now()}`"
                v-model="block.isStateRequired"
                  ></b-form-checkbox>
      </label>
      
      <label for="" class="editorStyleTitle mt-2 d-flex align-items-center justify-content-between">
        <span>Placeholder Text:</span>
       
      </label>
      <input type="text"  placeholder="Sample Text" v-model="block.StatePlaceholder" class="w-100 editor-input-control" id="">
    </div>
            </b-collapse>
          </div>
              <!-- State End -->
               <!-- Zip Start -->
               <div class="each-custom-address">
            <div v-b-toggle.ZipCode-button-collapse style=" cursor: pointer; border-bottom: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div class="d-flex" style="gap:4px; color: #18191C; font-weight: 500; font-size: 16px" s><b-form-checkbox
                       :id="`ZipCode-${Date.now()}`"
                v-model="block.showZipCode"
                  ></b-form-checkbox>Zip/Postal Code</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
            <b-collapse accordion="button-address" id="ZipCode-button-collapse" class="mt-2">
              <div :class="!block.showZipCode
                  ? 'disabled-address'
                  : ''" class="px-3">
      <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Label text:</span>
        <span class="d-flex align-items-center gap">{{!block.showZipCodeLabel?'Show':'Hide'}}  <b-form-checkbox
                       :id="`ZipCode-show-title-${Date.now()}`"
                v-model="block.showZipCodeLabel"
                  ></b-form-checkbox></span>
      </label>
      <input type="text"  v-if="block.showZipCodeLabel" placeholder="ZipCode" v-model="block.ZipCodeLabel" class="w-100 editor-input-control" id="">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Required field:</span>
        <b-form-checkbox
                       :id="`isRequired-ZipCode-${Date.now()}`"
                v-model="block.isZipCodeRequired"
                  ></b-form-checkbox>
      </label>
      
      <label for="" class="editorStyleTitle mt-2 d-flex align-items-center justify-content-between">
        <span>Placeholder Text:</span>
       
      </label>
      <input type="text"  placeholder="Sample Text" v-model="block.ZipCodePlaceholder" class="w-100 editor-input-control" id="">
    </div>
            </b-collapse>
          </div>
                <!-- Zip End -->
                 <!-- Country Start -->
                 <div class="each-custom-address">
            <div v-b-toggle.Country-button-collapse style=" cursor: pointer; border-bottom: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div class="d-flex" style="gap:4px; color: #18191C; font-weight: 500; font-size: 16px" s><b-form-checkbox
                       :id="`Country-${Date.now()}`"
                v-model="block.showCountry"
                  ></b-form-checkbox>Country</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
            <b-collapse accordion="button-address" id="Country-button-collapse" class="mt-2">
              <div :class="!block.showCountry
                  ? 'disabled-address'
                  : ''" class="px-3">
      <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Label text:</span>
        <span class="d-flex align-items-center gap">{{!block.showCountryLabel?'Show':'Hide'}}  <b-form-checkbox
                       :id="`Country-show-title-${Date.now()}`"
                v-model="block.showCountryLabel"
                  ></b-form-checkbox></span>
      </label>
      <input type="text"  v-if="block.showCountryLabel" placeholder="Country" v-model="block.CountryLabel" class="w-100 editor-input-control" id="">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Required field:</span>
        <b-form-checkbox
                       :id="`isRequired-Country-${Date.now()}`"
                v-model="block.isCountryRequired"
                  ></b-form-checkbox>
      </label>
      
      <label for="" class="editorStyleTitle mt-2 d-flex align-items-center justify-content-between">
        <span>Placeholder Text:</span>
       
      </label>
      <input type="text"  placeholder="Sample Text" v-model="block.CountryPlaceholder" class="w-100 editor-input-control" id="">
    </div>
            </b-collapse>
          </div>
                  <!-- Country End -->

        </b-collapse>
        <div v-b-toggle.address-setting-button-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Style Setting</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="address-setting-button-collapse" class="mt-2">
          <div class="px-3">
  <SelectColor v-model="block.style.color"  :opacity.sync="block.style.textInputOpacity"  :label="'Text Color:'"></SelectColor>
  <TextAlign class="mt-2" v-model=block.style.textInputAlign ></TextAlign>
</div>
<hr>
<div class="px-3">
  <label for=""   class="editor-label">Stroke:</label>
  <SelectColor v-model="block.style.strokeColor"  :opacity.sync="block.style.strokeOpacity"  :label="'Text Color:'"></SelectColor>
  <div class="d-flex justify-content-center mt-2" style="gap:4px">
    <div class="d-flex text-align-section" style="gap:4px">
      <label for="" :class="{
                'label-active': block.style.borderType === 'border',
              }"
              @click="block.style.borderType = 'border'"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8333 2.5H4.16667C3.24167 2.5 2.5 3.24167 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V4.16667C17.5 3.24167 16.75 2.5 15.8333 2.5ZM15.8333 4.16667V15.8333H4.16667V4.16667H15.8333Z" fill="currentColor"/>
</svg>
</label>
<label for=""    :class="{
                'label-active': block.style.borderType === 'border-bottom',
              }"
              @click="block.style.borderType = 'border-bottom'" ><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5455 8.72727H13.091V7.27273H14.5455V8.72727ZM2.90914 8.72727H1.45459V7.27273H2.90914V8.72727ZM8.72732 2.90909H7.27277V1.45454H8.72732V2.90909ZM11.6364 2.90909H10.1819V1.45454H11.6364V2.90909ZM14.5455 5.81818H13.091V4.36364H14.5455V5.81818ZM14.5455 2.90909H13.091V1.45454H14.5455V2.90909ZM14.5455 11.6364H13.091V10.1818H14.5455V11.6364ZM2.90914 11.6364H1.45459V10.1818H2.90914V11.6364ZM5.81823 2.90909H4.36368V1.45454H5.81823V2.90909ZM2.90914 2.90909H1.45459V1.45454H2.90914V2.90909ZM2.90914 5.81818H1.45459V4.36364H2.90914V5.81818ZM14.5455 14.5455H1.45459V13.0909H14.5455V14.5455Z" fill="currentColor"/>
</svg>
</label>
    </div>
  <Stroke :borderSize.sync="block.style.borderSize"
  :borderRadius.sync="block.style.borderRadius"  ></Stroke>
</div>
 </div>
 <hr class="mb-0">
        </b-collapse>
        <div class="px-3 mt-2 mb-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>
 
</div>
</template>
<script>
import Padding from '../MiniComponents/Padding.vue';
import SelectColor from '../MiniComponents/SelectColor.vue';
import Stroke from '../MiniComponents/Stroke.vue';
import TextAlign from '../MiniComponents/TextAlign.vue';
export default {
  components:{
    SelectColor,
    TextAlign,
    Stroke,
    Padding
  },
  props: {
    block: Object,
    isDesktopView: Boolean,
    addedBlocks: Array,
  },
  data() {
    return {};
  },

  methods: {
    checkExistingAddresField(input) {
      let allInputs = [...this.addedBlocks];
      if (allInputs.length) {
        return allInputs.includes(input);
      }

      return false;
    },
  },
  computed: {},
};
</script>
<style scoped>
.label-active {
    border-radius: 6px!important;
border: 1px solid #D2D8E0!important;
background: #FFF!important;
color: black!important;
        }
.disabled-address{
  opacity: 0.5;
  pointer-events: none;
}
.headingText strong {
  font-size: 14px;
  font-weight: 500 !important;
}
.reset-option {
  border: 0.3px solid #6d6b6d;
  box-sizing: border-box;
  border-radius: 4px;
}
/* .editorStyleTitle input {
  border-color: #b3afb6;
  font-size: 14px;
}
.editorStyleTitle {
  font-weight: 400 !important;
} */
.text-setting select {
  font-size: 14px;
  padding: 0;
}
.ql-container.ql-snow {
  background: white;
}
.button-group {
  color: #535353;
  outline: #c4c4c4 solid 1px;
}
.button-selected {
  background: #000000 !important;
  color: #ffffff !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
            background-color: #ffc107 !important; 
            border-color: #ffc107 !important; 
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #fff !important; 
        }

/* .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
} */

/* Button Group CSS Style */

.productViewSelected {
  background: #ffa201 !important;
  color: #ffffff !important;
}
</style>
